<template>
  <div class="base" id="onboard">
    <main class="main-body">
      <div class="security-body" id="register-body">
        <div class="header-container">
          <div class="logo-wrap">
            <img :src="require('../assets/images/savvix-logo-white.png')" width="200" height="40">
          </div>        
          <div class="header-wrap">
            <div class="text-center mt-1 title">{{$t("ONBOARDING FORM")}}</div>
            <div class="text-center mt-2">{{$t("Answer a few questions and we&#39;ll contact you as soon as possible.")}}</div>
            <!-- <div class="text-center mt-3">Please Note:</div> -->
            <div class="text-center">{{$t("This form is for businesses wanting to integrate SavvixPay.")}}</div>
            <div class="text-center mb-2">{{$t("For customer support, email info@savvix.io")}}</div>
          </div>
        </div>
        <form id="register" @submit.stop.prevent="submitForm()">
          <div class="form-group user-type-label mt-4">
            <div class="lower-form-label">{{$t("How would you like to use SavvixPay?")}}</div>
            <div class="radio-group">
              <div class="radio-option">
                <input type="radio" id="personal" class="" value="personal" v-model="$v.form.usageType.$model"/>
                <label for="personal">{{$t("Personal")}}</label>
              </div>
              <div class="radio-option">
                <input type="radio" id="business" class="" value="business" v-model="$v.form.usageType.$model"/>
                <label for="business">{{$t("Business")}}</label>
              </div>
            </div>
          </div>
          <div class="form-group" v-if="$v.form.usageType.$model == 'business'">
            <div class="lower-form-label">{{$t("How would you categorize your business?")}}</div>
            <div>
              <select v-model="category" required>
                <option value="" disabled>{{$t("Select Category")}}</option>
                <option v-for="value in categories" v-bind:key="value" :value="value" >{{ value }}</option>
              </select>
            </div>
            <b-form-invalid-feedback id="input-1-live-feedback">{{$t("This is a required field.")}}</b-form-invalid-feedback>
          </div>
          <div class="form-group">
            <label for="Name" v-if="$v.form.usageType.$model == 'personal'">{{$t("Full Name")}}</label>
            <label for="Name" v-else>{{$t("Company Name")}}</label>
            <b-form-input
            id="example-input-1"
            :placeholder="$v.form.usageType.$model == 'personal' ? 'Enter Full Name' : 'Enter Company Name'"
            name="example-input-1"
            v-model="$v.form.name.$model"
            :state="validateState('name')"
            aria-describedby="input-1-live-feedback"
            required
            ></b-form-input>
            <b-form-invalid-feedback id="input-1-live-feedback">{{$t("This is a required field.")}}</b-form-invalid-feedback>
          </div>
          <div class="form-group">
              <label for="email" v-if="$v.form.usageType.$model == 'personal'">{{$t("Personal Email")}}</label>
              <label for="email" v-else>{{$t("Company Email")}}</label>
              <b-form-input
                id="example-input-1"
                :placeholder="$v.form.usageType.$model == 'personal' ? 'Enter Personal Email' : 'Enter Company Email'"
                name="example-input-1"
                v-model="$v.form.email.$model"
                :state="validateState('email')"
                aria-describedby="input-1-live-feedback"
                required
              ></b-form-input>
              <b-form-invalid-feedback id="input-1-live-feedback">{{$t("Please input a valid email.")}}</b-form-invalid-feedback>
          </div>

          <div class="form-group">
            <label for="website">{{$t("Website Url")}}</label>
            <b-form-input
              id="example-input-1"
              :placeholder="$t(`Enter Website Url`)"
              name="example-input-1"
              v-model="$v.form.website.$model"
              :state="validateState('website')"
              aria-describedby="input-1-live-feedback"
              required
            ></b-form-input>
            <b-form-invalid-feedback id="input-1-live-feedback">{{$t("Please input a valid url.")}}</b-form-invalid-feedback>
          </div>

          <hr class="rounded">

          <div class="form-group">
            <div class="lower-form-label">{{$t("How many monthly transactions do you anticiapte doing through SavvixPay?")}}</div>
            <b-form-input
              id="example-input-1"
              name="example-input-1"
              v-model="$v.form.monthly.$model"
              :state="validateState('monthly')"
              aria-describedby="input-1-live-feedback"
              type="number"
              min="0"
              step="1"
              :placeholder="$t(`Enter Amount`)"
              required
            ></b-form-input>
            <b-form-invalid-feedback id="input-1-live-feedback">{{$t("This is a required field.")}}</b-form-invalid-feedback>
          </div>

          <div class="form-group">
            <div class="lower-form-label">{{$t("When will you be ready to integrate?")}}</div>
            <div class="split-box-group">
              <select v-model="$v.form.readyByWeek.$model" :state="validateState('readyByWeek')" required>
                <option value="" disabled>{{$t("Select Week")}}</option>
                <option v-for="week in weeks" v-bind:key="week" :value="week" >{{ week }}</option>
              </select>
              <select v-model="$v.form.readyByMonth.$model" :state="validateState('readyByMonth')" required>
                <option value="" disabled>{{$t("Select Month")}}</option>
                <option v-for="month in months" v-bind:key="month" :value="month" >{{ month }}</option>
              </select>
            </div>
            <b-form-invalid-feedback id="input-1-live-feedback">{{$t("This is a required field.")}}</b-form-invalid-feedback>
          </div>

          <div class="form-group">
            <div class="lower-form-label">{{$t("Kindly Tell us more about your project")}}</div>
            <textarea 
              v-model="$v.form.details.$model"
              :state="validateState('details')"
              aria-describedby="input-1-live-feedback"
              :placeholder="$t(`Max 100 characters....`)"
            ></textarea>
            <b-form-invalid-feedback id="input-1-live-feedback">{{$t("This is a required field.")}}</b-form-invalid-feedback>
          </div>

          <div class="row w-100 checkbox-wrap" style="margin: 0px;">
            <!-- <div style="width: 10%">
              <input type="checkbox" id="policy" name="policy">
            </div> -->
            <div class="checkbox-group">
                <input type="checkbox" id="custom-checkbox" class="custom-checkbox" required>
                <span class="custom-checkbox-span" tabindex="0"></span>
            </div>
            <div class="small" style="width: 90%">
              <span>{{$t("I am over 18 years old and I have read and agree to the terms of the ")}}
                {{$t("Privacy Policy ")}}
                {{$t("and ")}}
                {{$t("Terms Of Use")}}</span>
            </div>
          </div>

          <div class="d-flex justify-content-center mt-3">
            <button type="submit" class="security-btn">{{$t("Submit Form")}}</button>
          </div>
        </form>

        <div class="mt-2 mb-4 d-flex justify-content-center" id="bottom-container">
          <div>
            <span class="mr-2">{{$t("Already have an account?")}}</span>
            <router-link class="signin-btn" to="/login">
              {{$t("Sign In")}}
            </router-link>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import { required, email, maxLength } from "vuelidate/lib/validators";
// import api from '../helper/api.js';

export default {
  data() {
    return {
      form: {
        name:'',
        email: '',
        website: '',
        usageType: 'personal',
        monthly: '',
        readyByWeek: '',
        readyByMonth: '',
        details: ''
      },
      category: '',
      categories: ['Payment', 'e-commerce', 'Gaming', 'Metaverse', 'DeFi', 'CeFi', 'Wallet', 'NFT'],
      weeks: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    };
  },
  validations: {
    form: {
      name: {
        required
      },
      email: {
        required,
        email
      }, 
      website:{
        required,
        // url
      },
      usageType:{
        required
      },
      monthly:{
        required
      },
      readyByWeek:{
        required
      },
      readyByMonth:{
        required
      },
      details:{
        maxLengthValue: maxLength(100)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async submitForm(){
      console.log('submit')
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        // console.log('formerror')
        if (this.$v.form.details.$anyError) {
          this.$swal('Error', 'Details cannot be more than 100 characters','error');
          return
        }
        this.$swal('Error', 'Form not submitted','error');
        return;
      }

      try{
        let params = {
          name : this.form.name,
          email : this.form.email,
          website : this.form.website,
          usage_type : this.form.usageType,
          category : this.form.usageType == 'business' ? this.category : null,
          monthly : this.form.monthly,
          // ready_by : this.form.readyByMonth + ' ' + this.form.readyByWeek,
          details : this.form.details
        }
        let baseUrl = process.env.VUE_APP_CORE_BASE_URL;
        const response = await axios.post(`${baseUrl}/onboarding-form/submit`,params);
        console.log(params)
        console.log(response)
        this.$router.push({name:'Landing'});
        this.$swal('Success', response.message,'success');

      }catch(error){
        this.$swal('Error',error.response.data.message,'error');
        console.log(error);
      }

    },

  }
};
</script>

<style>
@import '../css/styles.css';

#register-body {
  margin-top: 3%;
  width: 45%;
}

#bottom-container {
  color: #c6c8dd;
}

.signin-btn {
  color: var(--primary);
  cursor: pointer;
  outline: none;
  text-decoration: none;
}

.signin-btn:hover {
  cursor: pointer;
  outline: none;
  text-decoration: none;
  opacity: 0.5;
}

.split-box-group {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.split-box {
  width: 100%;
  max-width: 50%;
}

hr.rounded {
  border-top: 2px solid #bbb;
  border-radius: 5px;
}

select {
  height: calc(1.5em + 0.75rem + 2px)
}

textarea {
  resize: none;
  padding: 10px 20px;
}

.hidden-half-div {
  width: 50%;
}

.lower-form-label {
  padding: 5px;
  display: flex;
  justify-content: center;
  /* margin-left: 1.6rem; */
}

.radio-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin-left: 1.6rem; */
}

.radio-option {
  display: flex;
  flex-direction: row;
  margin-left: 2rem;
}

@media only screen and (max-width: 820px) {
  #register-body {
    margin:10% 5%;
    width: 100%;
  }

  .split-box-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .split-box {
    max-width: 100%;
  }
}

/* NEW UPDATE */
#register-body .form-group label, #register-body .form-group .lower-form-label{
  justify-content: left;
  color: var(--info);
  font-weight: 600;
}

#register-body input{
  text-align: left;
  font-size: 14px;
  
}

#register-body input::placeholder{
  color: var(--inactive);
  padding-left: 26px;

}

#register-body .checkbox-wrap{
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 40px !important;
}

#register-body .checkbox-wrap .small{
  max-width: 396px;
}

#register-body select{
  text-align: left;
  font-size: 14px;
  color: var(--inactive);
  padding-left: 26px;
}

#register-body textarea{
  padding-left: 20px;
}

#register-body textarea::placeholder{
  font-size: 14px;
  color: var(--inactive);
  padding-left: 20px;
}

#register-body .user-type-label .lower-form-label{
  justify-content: center !important;
}

#register-body .radio-group{
  gap: 24px;
}

#register-body .radio-option{
  margin-left: 0;
  gap: 8px;
  align-items: center;
}

#register-body .radio-option label{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    margin-bottom: 0;
}

#onboard #register-body{
  width: 100%;
  margin-top: 0;
}

#onboard #register{
  width: 50%;
  margin: auto;
}

#register-body .logo-wrap{
  /* margin-top: 3%; */
  text-align: center;
  position: absolute;
  left: 4%;
  top: 32px;
}

.logo-wrap img{
  max-width: 150px;
  height: auto;
}

.header-container{
  padding-top: 30px;
  padding-bottom: 12px;
  position: relative;
  background: var(--info);
}

.header-wrap{
  color: var(--light);
}

.header-wrap .title{
  font-size: 16px;
  font-weight: 700;
}

#register-body .signin-btn{
  text-decoration: underline;
}

#register-body #bottom-container{
  font-size: 14px !important;
  color: var(--black) !important;
}

#onboard .custom-checkbox-span{
  border-radius: 6px !important;
}

#onboard .security-btn{
  background: var(--primary);
  color: var(--light);
  font-weight: 600;
  font-size: 14px;
  padding: 12px 8px;
}

@media only screen and (max-width: 820px) {
  #onboard #register-body {
    margin:0 !important;
    width: 100%;
  }

  #onboard #register{
    padding: 0 5%;
    width: 100%;
  }

  #register-body .logo-wrap{
    position: initial;
    margin-bottom: 26px;
  }

}

</style>